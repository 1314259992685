<template>
  <div class="vue-truncate-html-example">
    <vue-dadata
        v-model="query"
        :token="token"
        :onChange="alert"
        v-model:suggestion="suggestion"
        :locationOptions="locationOptions"
        :autocomplete="true"
        :debounce-wait="300"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { VueDadata } from 'vue-dadata';
import 'vue-dadata/dist/style.css';

export default defineComponent({
  name: 'VueTruncateHtmlExample',
  components: {
    VueDadata,
  },
  methods: {
    alert() {
      this.$emit('update:suggestion', this.suggestion)
    }
  },
  setup() {
    const query = ref('');
    const suggestion = ref(undefined);

    return {
      token: 'ca43795df5af118ab69d1d273dde945491d21e59',
      locationOptions: {
        locations: {
          city: "Омск"
        }
      },
      query,
      suggestion

    };
  },
});
</script>