import { defineStore } from 'pinia'
export const useTelegramStore = defineStore({
    id: 'telegram',
    state: () => ({
        user: {
            id: null,
        },
    }),
    getters: {
        tguser: (state) => state.user,
    },
    actions: {
        setStore(data) {
            this.user = data
        },
    },
})