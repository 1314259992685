<script setup>
import {useCartStore} from "@/store/cart";
import {usePersistCart} from "@/composables/usePersistCart";
import {computed} from "vue";
import router from "@/router/router";

const cartStore = useCartStore()
usePersistCart()
const count = computed(() => cartStore.count)
const total = computed(() => cartStore.total)
const props = defineProps(['cartTotal'])

let tg = window.Telegram.WebApp;
tg.MainButton.text = "Коризна "+props.cartTotal+" ₽";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  router.push('/cart');
});
</script>

<template>
<div data-portal="" style="position: fixed; z-index: 50000;" class="MobileUIBottomBar_portal">
	<div class="MobileUIBottomBar_root" data-testid="mobile-ui-bottom-bar-root">
			<div class="CartBarLayout_root CartBarLayout_footer" v-bind:class="getClass()">
				<div class="CartButtonFooter_root">
					<div class="CartBottomBannerContent_root" data-testid="bottom-banner-root">
						<div class="CartBottomBannerContent_iconWrapper">
							<div class="CartBottomBannerContent_icon" style="background-image: url(&quot;https://avatars.mds.yandex.net/get-bunker/118781/6a55adcf97256a37dbd80ba03f74758edaff00be/svg&quot;);"></div>
						</div>
						<div class="CartBottomBannerContent_main">
							<div class="CartBottomBannerContent_title" style="color: rgb(90, 195, 26);" data-testid="bottom-banner-name">Доставка от 800 ₽</div>
							<div class="CartBottomBannerContent_description CartBottomBannerContent_title" data-testid="bottom-banner-description"></div>
						</div>
						<div class="CartBottomBannerContent_iconWrapper">
							<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root">
								<g clip-path="url(#chevron-right-mini_svg__a)">
									<path d="M6.333 4.25 10.083 8l-3.75 3.75" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"></path>
								</g>
								<defs>
									<clipPath id="chevron-right-mini_svg__a">
										<path fill="#fff" d="M0 0h16v16H0z"></path>
									</clipPath>
								</defs>
							</svg>
						</div>
					</div>
				</div>
				<button class="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MobileCartButton_root" @click="$router.push('/cart')"><span>{{ cartItems }} товаров<div class="MobileCartButtonContent_root"><div class="MobileCartButtonContent_wrapper MobileCartButtonContent_center">Корзина</div></div><div class="MobileCartButtonBubble_root"><span class="TemplatedPrice_root">{{ cartTotal }} ₽</span></div>
			</span>
			</button>
		</div>
</div>
</div>
</template>

<script>
	export default {
		name: 'BottomBar',
		props: {
			cartItems: {
				type: Number,
				default: 0,
				required: false
			},
			cartTotal: {
				type: Number,
				default: 0,
				required: false
			}
		},
		methods: {
			getClass(){
				if(this.cartItems > 0) {
					this.$emit('showBottomBar');
					return 'CartBarLayout_visible';
				} else {
					this.$emit('hideBottomBar');
					return "";
				}

			},
		}
	}
</script>

<style scoped>

.MobileUIBottomBar_portal {
  left: 0;
  width: 100%;
  bottom: 0;
  position: fixed;
}
.MobileUIBottomBar_root {
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
}
.CartBarLayout_footer {
  border-radius: 24px 24px 0 0;
}

.CartBarLayout_root {
  padding-bottom: 8px;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: none;
position: relative;
  padding: 8px 8px 16px;
    padding-bottom: 16px;
  transform: translateY(100%);
  box-shadow: 0px -4px 20px rgba(0,0,0,0.4);
  transition: all 200ms ease-in;
  padding-bottom: max(var(--safe-area-inset-bottom, 8px), 16px);
  background-color: #42413E;
  pointer-events: initial;
  z-index: -2222;
  visibility: hidden;
}
body:not(.itemDrawerOpen) .CartBarLayout_visible {
  opacity: 1;
  z-index: 1;
  display: block;
  visibility: visible;
  transform: translateY(0);
  pointer-events: initial;
}
.CartButtonFooter_root {
  padding: 4px 0 12px 8px;
}
.CartBottomBannerContent_root {
  display: flex;
  font-size: 13px;
  text-align: center;
  align-items: center;
  line-height: 15px;
  justify-content: space-between;
  background-color: #42413E;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.CartBottomBannerContent_iconWrapper {
  flex: 0 0 auto;
  width: 24px;
  margin-right: 10px;
}
.CartBottomBannerContent_icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
.CartBottomBannerContent_main {
  flex: 0 1 auto;
  min-width: 1px;
}
.CartBottomBannerContent_title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CartBottomBannerContent_description {
  margin-top: 2px;
}
.CartBottomBannerContent_iconWrapper:last-child {
  height: 26px;
  margin-left: 10px;
  margin-right: 0;
}
.UiKitButton_shape-default.UiKitButton_size-m, .UiKitButton_shape-default.UiKitButton_size-l {
  border-radius: 16px;
}
.MobileCartButton_root {
  width: 100%;
  display: block;
  padding: 0 16px;
  overflow: visible;
}
.UiKitButton_variant-action {
  color: #21201F;
  font-weight: 500;
  background-color: #FCE000;
}
.MobileCartButtonContent_root {
  padding: 0 8px;
  overflow: hidden;
  margin-right: auto;
  color: black;
}
.MobileCartButtonContent_center {
  display: flex;
  position: absolute;
  align-items: center;
  padding-bottom: 2px;
  justify-content: center;
}
.MobileCartButtonBubble_root {
  flex: 0 0 auto;
  padding: 7px 10px;
  font-size: 14px;
  color: black;
  font-weight: normal;
  border-radius: 3px;
}
.TemplatedPrice_root {
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  color: black;
}
.MobileCartButtonContent_wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartButton_root > span {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  line-height: normal;
  color: black;
}
.UiKitButton_size-l {
  height: 56px;
  padding: 0 24px;
}
.UiKitButton_root {
  color: #E0DEDA;
  border: none;
  display: flex;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease-out;
  align-items: center;
  line-height: 17px;
  justify-content: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.CartBottomBannerContent_iconWrapper {
  flex: 0 0 auto;
  width: 24px;
  margin-right: 10px;
}
</style>