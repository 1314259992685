<template>
  <div>
    <div class="navdrawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
      <div
        class="navdrawer__overlay"
        :style="{ transitionDuration: `${speed}ms` }"
      ></div>
      <div
        class="navdrawer__content"
        v-click-away="closeNavDrawer"
        :style="{
          maxWidth: maxWidth,
          transitionDuration: `${speed}ms`,
          backgroundColor: backgroundColor,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "NavDrawer",

  directives: {
    ClickAway: directive,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxWidth: {
      type: String,
      required: false,
      default: "400px",
    },

    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300,
    },

    backgroundColor: {
      type: String,
      required: false,
      default: "#fafafa",
    },
  },

  data() {
    return {
      isVisible: false,
      isTransitioning: false,
    };
  },

  watch: {
    isOpen(val) {
      this.isTransitioning = true;

      if (val) {
        this.toggleBackgroundScrolling(true);
        this.isVisible = true;
      } else {
        this.toggleBackgroundScrolling(false);
        setTimeout(() => (this.isVisible = false), this.speed);
      }

      setTimeout(() => (this.isTransitioning = false), this.speed);
    },
  },

  methods: {
    toggleBackgroundScrolling(enable) {
      const body = document.querySelector("body");

      body.style.overflow = enable ? "hidden" : null;
    },

    closeNavDrawer() {
      if (!this.isTransitioning) {
        this.$emit("close");
      }
    },
  },

  mounted() {
    this.isVisible = this.isOpen;
  },
};
</script>

<style scoped>
.navdrawer {
   visibility: hidden;
   z-index: 555;
}
 .navdrawer.is-visible {
   visibility: visible;
}
 .navdrawer.is-open .navdrawer__overlay {
   opacity: 0.5;
}
 .navdrawer.is-open .navdrawer__content {
   transform: translateX(0);
}
 .navdrawer__overlay {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   z-index: 200;
   opacity: 0;
   transition-property: opacity;
   background-color: #000;
   user-select: none;
}
 .navdrawer__content {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   height: 100%;
   width: 100%;
   z-index: 9999;
   overflow: auto;
   transition-property: transform;
   display: flex;
   flex-direction: column;
   transform: translateX(-100%);
/*   box-shadow: 0 2px 6px #777;*/
}
 
</style>