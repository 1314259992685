<template>
	<div class="RestaurantPageNewHeaderTopBar_topBarContainer RestaurantPageNewHeaderTopBar_floating" v-bind:class="getClass('scroll')">

		<div class="RestaurantPageNewHeaderTopBar_whiteTB">
<!--			<div class="MobileHeaderContainer_iconWrapper MobileHeaderContainer_icon" @click="$emit('openNav')" v-if="homePage()">-->
<!--				<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileHeaderContainer_burgerIcon">-->
<!--					<path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v1Zm0 5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v1Zm.5 5.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-15Z" fill="currentColor"></path>-->
<!--				</svg>-->
<!--			</div>-->
			<div class="Clickable_root Clickable_rootWithAnimation RestaurantPageNewHeaderTopBar_headBtn" v-if="!homePage()" @click="$router.back()">
				<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root RestaurantPageNewHeaderTopBar_topbarIcon">
					<path fill-rule="evenodd" clip-rule="evenodd" d="m7.8 11 5.246-5.246a.5.5 0 0 0 0-.708l-.692-.692a.5.5 0 0 0-.708 0L4 12l7.646 7.646a.5.5 0 0 0 .708 0l.692-.692a.5.5 0 0 0 0-.708L7.8 13h11.7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H7.8Z" fill="currentColor"></path>
				</svg>
			</div>
			<div @click="$router.push('/')" class="RestaurantPageNewHeaderTopBar_headTitle RestaurantPageNewHeaderTopBar_hasFavoriteButton">Царёвы Пироги</div>
<!-- 			<div class="FavoriteButton_root MobileFavoriteButton_buttonRoot RestaurantPageNewHeaderTopBar_headBtn">
				<div class="FavoriteIcon_root RestaurantPageNewHeaderTopBar_favBtnIcon FavoriteIcon_withBackground FavoriteIcon_stateOff">
					<div class="FavoriteIcon_icon">
						<svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 18.0433L10.6154 17.8179C10.2218 17.5925 1 12.1779 1 6.38461C1 3.41587 3.41587 1 6.38462 1C8.34075 1 10.0565 2.04868 11 3.61418C11.9435 2.04868 13.6593 1 15.6154 1C18.5841 1 21 3.41587 21 6.38461C21 12.1779 11.7782 17.5925 11.3846 17.8179L11 18.0433Z" stroke="#21201F" stroke-width="2"></path>
						</svg>
					</div>
				</div>
			</div> -->
			<div class="RestaurantPageNewHeaderTopBar_headBtn" @click="$router.push('/cart')">
			  <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger" v-if="cartItems > 0">
			    {{ cartItems }}
			    <span class="visually-hidden">empty cart</span>
  </span>

			<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.887 20.6L7.03 7h9.942l1.142 13.6H5.887z" stroke="#fff" stroke-width="2"/><path d="M9 9V5.4v0a3 3 0 013-3v0a3 3 0 013 3V9" stroke="#fff" stroke-width="2"/></svg>
			</div>
		</div>
	</div>
</template>

<script>
	import debounce from 'lodash/debounce';
	export default {
		name: 'header-component',
		props: {
			itemToShow: {
				type: Object,
				required: true,
			},
			cartItems: {
				type: Number,
				default: 0,
				required: false
			}
		},
		data() {
			return {
				scroll: false,
			}
		},
		methods: {
			handleScroll(event) {
			  // Any code to be executed when the window is scrolled
			  this.isUserScrolling = (window.scrollY > 0);
			  if(window.scrollY > 100) {
			  	this.scroll = true;
			  } else {
			  	this.scroll = false;
			  }
			  console.log('calling handleScroll');
			},
			getClass(property){
				if(this.homePage()) {
					return 'RestaurantPageNewHeaderTopBar_floating';
				} else {
					return {
					  'RestaurantPageNewHeaderTopBar_floating': this.scroll,
					  '': !scroll
					}
				}
			},
			homePage() {
				if(this.$route.path == "/" || this.$route.path == "/home" ) {
				  return true
				} else {
				  return false
				}
			}
		},
		mounted() {
			this.handleDebouncedScroll = debounce(this.handleScroll, 100);
			window.addEventListener('scroll', this.handleDebouncedScroll);
		},

		beforeDestroy() {
			// I switched the example from `destroyed` to `beforeDestroy`
			// to exercise your mind a bit. This lifecycle method works too.
			window.removeEventListener('scroll', this.handleDebouncedScroll);
		}
	}
</script>