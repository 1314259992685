<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { defineProps } from 'vue'
import { useCartStore } from '@/store/cart'
import { useProductStore } from '@/store/products'
import ProductsElement from '@/components/ProductsElement'

const route = useRoute();
const productStore = useProductStore();
productStore.fetchItem(route.params.itemId);
const item = computed(() => productStore.getItem);
const cartStore = useCartStore();</script>
<template>
<div class="MobileUIBottomSheet_baseContainer MobileUIBottomSheet_childContainer MobileUIBottomSheet_baseContainerOpened MobileUIBottomSheet_baseContainerTransitioned">
	<div class="MobileUIBottomSheet_inner">
		<div data-testid="ui-bottom-sheet-inner">
			<div class="MobileUIBottomSheetLayout_root" style="max-height: 820px;" data-testid="add-to-cart-item-options-root">
				<div class="MobileUIBottomSheetBody_root AddToCartOptionsShadowableBottomSheetBody_root MobileAddToCartOptions_body">
					<div class="MobileUIShadowableScrollBox_root AddToCartOptionsShadowableBottomSheetBody_scrollbox">
						<div class="MobileUIShadowableScrollBox_shadow AddToCartOptionsShadowableBottomSheetBody_shadow MobileAddToCartOptions_shadow" style="box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px inset;"></div>
						<div class="MobileUIShadowableScrollBox_content">
							<div class="MobileAddToCartOptions_imageWrapper">
								<div class="SmartImage_root MobileAddToCartOptions_imageRoot">
									<img :src="item.pictures" class="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" data-testid="smart-image-img" loading="lazy"></div>
							</div>
							<div class="HTMLDescription_root MobileAddToCartOptions_htmlDescription" v-html="item.sostav"></div>
						</div>
					</div>
				</div>
				<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileAddToCartOptions_close" @click="$router.back()">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M18.646 6.754a.5.5 0 0 0 0-.708l-.692-.692a.5.5 0 0 0-.708 0L12 10.6 6.754 5.354a.5.5 0 0 0-.708 0l-.692.692a.5.5 0 0 0 0 .708L10.6 12l-5.246 5.246a.5.5 0 0 0 0 .708l.692.692a.5.5 0 0 0 .708 0L12 13.4l5.246 5.246a.5.5 0 0 0 .708 0l.692-.692a.5.5 0 0 0 0-.708L13.4 12l5.246-5.246Z" fill="currentColor"></path>
				</svg>
				<div class="MobileUIBottomSheetFooter_root">
					<div class="MenuItemCountEdit_root">
						<div class="MenuItemCountEdit_row MenuItemCountEdit_titleRow">
							<h2 class="MenuItemCountEdit_title">{{ item.title }}</h2>
							<div class="MenuItemCountEdit_price"><span class="Price_root">{{ item.price }} ₽</span></div>
						</div>
						<div class="MenuItemCountEdit_row MenuItemCountEdit_counter">
							<div class="MenuItemCountEdit_counterContainer MenuItemCountEdit_common">
								<span class="UICounter_root UICounter_sm" data-testid="ui-counter-root">
									<div class="UICounter_iconDecrement UICounter_icons UICounter_button UICounter_simple" @click="cartStore.remove(item)">–</div>
									<div class="UICounter_value MenuItemCountEdit_counterCommonValue" data-testid="ui-counter-count">{{ cartStore.getItemCount(item.id) }}</div>
									<div class="UICounter_iconIncrement UICounter_icons UICounter_button UICounter_simple" @click="cartStore.add(item)">+</div>
								</span>
							</div>
							<button class="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MenuItemCountEdit_confirm MenuItemCountEdit_common" data-testid="menu-item-count-edit-confirm" @click="$router.back()"><span>Добавить</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	props: ["itemId"],

};
</script>

<style scoped>
.MobileUIBottomSheet_baseContainerTransitioned {
  transition: .3s ease transform;
}
.MobileUIBottomSheet_baseContainerOpened {
  transform: translateY(0);
}
.MobileUIBottomSheet_baseContainer {
  top: auto;
  z-index: 2;
  will-change: transform;
}
.MobileUIBottomSheet_childContainer {
  bottom: 0;
  position: fixed;
  background: transparent;
}
.MobileUIBottomSheet_inner {
  overflow: hidden;
  position: relative;
  background: #191918;
  box-shadow: 0 -4px 20px rgba(117, 115, 111, .2);
  min-height: 64px;
  border-radius: 24px 24px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.MobileUIBottomSheetLayout_root {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  max-height: 100%;
  flex-direction: column;
}
.MobileAddToCartOptions_body {
  color: #9E9B98;
  background: #302F2D;
  min-height: 64px;
}
.AddToCartOptionsShadowableBottomSheetBody_root {
  display: flex;
}
.MobileUIBottomSheetBody_root {
  flex: 0 1 auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 24px 24px 0 0;
}
.AddToCartOptionsShadowableBottomSheetBody_scrollbox {
  flex: 1;
  width: 100%;
  display: flex;
}
.MobileUIShadowableScrollBox_shadow {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}
.MobileUIShadowableScrollBox_root {
  position: relative;
}
.MobileUIShadowableScrollBox_content {
  width: 100%;
  z-index: 1;
  overflow-x: auto;
  overflow-y: auto;
}

.MobileAddToCartOptions_imageRoot {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #191918;
}
.SmartImage_visibleImg {
  opacity: 1;
}
.SmartImage_coverFitImg {
  width: 100%;
  object-fit: cover;
}
.SmartImage_animatedImg {
  transition: opacity 200ms;
}

.MobileAddToCartOptions_htmlDescription {
  padding: 16px;
  font-size: 14px;
  word-break: break-word;
  line-height: 22px;
}
.MobileUIBottomSheetFooter_root {
  padding-bottom: 16px;
  flex: 0 0 auto;
    flex-shrink: 0;
  width: 100%;
  padding: 8px 8px 16px;
    padding-bottom: 16px;
  z-index: 5;
  background: #42413E;
  border-top: 1px solid rgba(0, 0, 0, .05);
  flex-shrink: 0;
  border-radius: 0;
  padding-bottom: max(env(safe-area-inset-bottom), 16px);
}
.MobileAddToCartOptions_close {
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  padding: 8px;
  z-index: 6;
  overflow: visible;
  position: absolute;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
  border-radius: 50%;
  background-color: #191918;
  background-repeat: no-repeat;
  background-position: center;
}
.SmartImage_cover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-size: 200px;
  background-color: #302F2D;
  background-position: center;
}
.SmartImage_root {
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
}
.MenuItemCountEdit_titleRow {
  padding: 0 8px;
}
.MenuItemCountEdit_row {
  width: 100%;
  display: flex;
}
.MenuItemCountEdit_title {
  flex: 0 1 auto;
  color: #E0DEDA;
  word-break: break-word;
  line-height: 1.25;
  margin-right: auto;
}
.MenuItemCountEdit_price {
  flex: 0 0 auto;
  color: #E0DEDA;
  align-self: center;
  line-height: 1.25;
  padding-left: 4px;
}
.Price_root {
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
}
.MenuItemCountEdit_counter {
  margin-top: 16px;
}
.MenuItemCountEdit_row {
  width: 100%;
  display: flex;
}
.MenuItemCountEdit_counterContainer {
  display: flex;
  align-items: center;
  margin-right: 4px;
  justify-content: center;
  background-color: rgba(245,244,242,0.1);
}
.UICounter_sm.UICounter_root {
  line-height: 40px;
}
.UICounter_root {
  display: flex;
  text-align: center;
  align-items: center;
  line-height: 23px;
}
.UICounter_sm .UICounter_decrement, .UICounter_sm .UICounter_increment, .UICounter_sm .UICounter_iconDecrement, .UICounter_sm .UICounter_iconIncrement {
  width: 40px;
  height: 40px;
  font-size: 22px;
  line-height: 40px;
  border-radius: 12px;
}
.UICounter_disabled {
  cursor: default;
  opacity: 0.6;
  pointer-events: none;
}
.UICounter_iconDecrement {
  background-image: url("https://yastatic.net/s3/eda-front/www/assets/mobile.dark.f70b62a937d64a24ee43.svg");
}
.UICounter_iconIncrement {
  background-image: url("https://yastatic.net/s3/eda-front/www/assets/mobile.dark.32d646dca5332e4f5b4d.svg");
}
.UICounter_sm .UICounter_value {
  font-size: 16px;
  min-width: 40px;
}
.MenuItemCountEdit_counterCommonValue {
  color: #E0DEDA;
  margin: 0 10px;
}
.UiKitButton_shape-default.UiKitButton_size-m, .UiKitButton_shape-default.UiKitButton_size-l {
  border-radius: 16px;
}
.MenuItemCountEdit_confirm {
  flex: 1;
  margin-left: 4px;
}
.MenuItemCountEdit_common {
  border-radius: 16px;
}
.UiKitButton_variant-action {
  color: #21201F;
  font-weight: 500;
  background-color: #FCE000;
}
.UiKitButton_size-l {
  height: 56px;
  padding: 0 24px;
}
.UICounter_icons {
  color: transparent;
  font-size: 0;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
}
.UiKitButton_root {
  color: #1E1709;
  border: none;
  display: flex;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease-out;
  align-items: center;
  line-height: 17px;
  justify-content: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
</style>