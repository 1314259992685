<script setup>
import router from "@/router/router";
import {useCartStore} from "@/store/cart";
const cartStore = useCartStore();
cartStore.clearCart();
let tg = window.Telegram.WebApp;
tg.BackButton.hide();
tg.MainButton.text = "Закрыть окно заказа";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  tg.close();
});
setTimeout(() => tg.close(), 2000);

</script>

<template>
<p class="thank-p">Ваш заказ успешно оформлен! Оператор перезвонит Вам в ближайшее время для подтверждения заказа.</p>
</template>

<style scoped>
  .thank-p {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
</style>