import { createApp } from 'vue'
import axios from 'axios'
import router from '@/router/router'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia';
import config from '@/formkit.config.js'
import { plugin, defaultConfig } from '@formkit/vue'
import components from '@/components/UI'
import "bootstrap/dist/css/bootstrap.min.css"
import "../public/style.css"
import "../public/telegram-web-app.js"
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;





import App from './App'

const app = createApp(App);
components.forEach(component => {
	app.component(component.name, component);
});

const pinia = createPinia();




app.use(router).use(VueAxios, axios).use(pinia).use(plugin, defaultConfig(config)).mount("#app");





