<script setup>
import { useRoute } from 'vue-router'
import {computed, watch} from 'vue'
import { storeToRefs } from 'pinia'
import { defineProps } from 'vue'
import { useProductStore } from '@/store/products'
import ProductsElement from '@/components/ProductsElement'
import {useCartStore} from "@/store/cart";
import router from "@/router/router";

const route = useRoute();
const productStore = useProductStore();
productStore.fetchAll(route.params.slug);
const products = computed(() => productStore.list);
const slug = productStore.category;
const cartStore = useCartStore();
let tg = window.Telegram.WebApp;
tg.BackButton.show();
tg.MainButton.text = "Корзина "+cartStore.total +" ₽";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.setBackgroundColor("#191918");
tg.enableClosingConfirmation();
tg.themeParams.secondary_bg_color ="#191918";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
    router.push('/cart');
});
tg.BackButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('soft');
  router.back();
});
watch(
    () => cartStore.total,
    () => {
      tg.MainButton.text = "Корзина "+cartStore.total +" ₽";
    },
)


</script>
<template>
	<div class="MobileRestaurantComponentsCategory_root" v-if="productStore.loaded">
		<div class="MobileRestaurantComponentsCategory_header">
			<div class="MobileRestaurantComponentsCategory_label">
				<div class="MobileRestaurantComponentsCategory_titleAndCount">
					<h2 class="MobileRestaurantComponentsCategory_name">{{products[0].catTitle}}</h2></div>
        <div class="MobileRestaurantComponentsCategory_informers" style="padding: 10px 1px;" v-if="products[0].catTitle == 'Праздничное предложение'">
          <div class="UiKitCommonInformer_root UiKitCommonInformer_background UiKitCommonInformer_background-d7 MobilePlaceInformer_root UiKitCommonInformer_mobile UiKitCommonInformer_s" data-testid="informer">
            <div class="UiKitCommonInformer_mainContent">
              <div class="UiKitCommonInformer_text" style="padding: 10px 15px;" >
                <div class="UiKitCommonInformer_description " >Обращаем ваше внимание! Позиции из данного раздела готовятся по предзаказу за 24 часа до желаемого времени доставки или самовывоза.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="MobileRestaurantComponentsCategory_informers" style="padding: 10px 1px;" v-if="products[0].catTitle == 'Царевы пироги'">
          <div class="UiKitCommonInformer_root UiKitCommonInformer_background UiKitCommonInformer_background-d7 MobilePlaceInformer_root UiKitCommonInformer_mobile UiKitCommonInformer_s" data-testid="informer">
            <div class="UiKitCommonInformer_mainContent">
              <div class="UiKitCommonInformer_text" style="padding: 10px 15px;" >
                <div class="UiKitCommonInformer_description " >Обращаем Ваше внимание, пироги из данного раздела готовятся по предзаказу за 2-3 часа до желаемого времени доставки или самовывоза.</div>
              </div>
            </div>
          </div>
        </div>
			</div>

		</div>
		<div class="MobileRestaurantComponentsItemList_root MobileRestaurantComponentsCategory_items">
			<div class="MobileRestaurantComponentsItemList_container">
		      <products-element
		        v-for="product in products"
            :catSlug="slug"
		        :key="product.id"
		        :product="product"
		      />
		  </div>
		</div>
	</div>
  <Drawer :is-open="isDrawerOpen" :speed="500" :maxHeight="'650px'" @close="closeDrawer">
	<router-view @close="closeDrawer"></router-view>
  </Drawer>
</template>
<script>
export default {
		props: {
			catTitle: {
				type: String,
				required: true,
			}
		},
  data() {
    return {
      isDrawerOpen: false,
    };
  },
 watch: {
	$route: {
	      immediate: true,
	      handler: function(newVal, oldVal) {
	        newVal.meta && newVal.meta.showDrawer ? this.isDrawerOpen = true : this.isDrawerOpen = false;
	      }
	}
  },
  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },

    closeDrawer() {
      this.isDrawerOpen = false;
    },
  },
};
</script>

<style scoped>
.MobilePlaceInformer_root + .MobilePlaceInformer_root {
  margin-top: 8px;
}
.UiKitCommonInformer_s.UiKitCommonInformer_background {
  background-size: auto 56px;
  border-radius: 16px;
}

.UiKitCommonInformer_background-d7 {
  background-color: rgb(18, 18, 17);
}
.UiKitCommonInformer_mainContent {
  width: 100%;
  display: flex;
  align-items: center;
}

</style>