<script setup>
	import { computed } from 'vue'
	import { usePersistCart } from './composables/usePersistCart'
	import { useCartStore } from './store/cart'
	const cartStore = useCartStore()
	usePersistCart()
	const count = computed(() => cartStore.count)
	const total = computed(() => cartStore.total)

</script>

<template>
	<div class="app">
<!--		<NavDrawer :is-open="isNavDrawerOpen" background-color="#191918" :speed="500" :maxWidth="'285px'" @close="closeNavDrawer">-->
<!--			<div class="MobileSidebarMenu_header">-->
<!--				<svg width="1em" height="1em" @click="closeNavDrawer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileSidebarMenu_close">-->
<!--					<path fill-rule="evenodd" clip-rule="evenodd" d="M18.646 6.754a.5.5 0 0 0 0-.708l-.692-.692a.5.5 0 0 0-.708 0L12 10.6 6.754 5.354a.5.5 0 0 0-.708 0l-.692.692a.5.5 0 0 0 0 .708L10.6 12l-5.246 5.246a.5.5 0 0 0 0 .708l.692.692a.5.5 0 0 0 .708 0L12 13.4l5.246 5.246a.5.5 0 0 0 .708 0l.692-.692a.5.5 0 0 0 0-.708L13.4 12l5.246-5.246Z" fill="currentColor"></path>-->
<!--				</svg>-->
<!--				<div class="MobileSidebarMenu_logoContainer"><img class="MobileCommonLogo_root" src="https://proviant-omsk.ru/proviantmobile/img/logo-mobile.png"></div>-->
<!--			</div>-->
<!--			<div class="MobileUIShadowableScrollBox_root MobileSidebarMenu_body">-->
<!--				<div class="MobileUIShadowableScrollBox_shadow" style="box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0.05) 0px -8px 8px -4px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px inset;"></div>-->
<!--				<div class="MobileUIShadowableScrollBox_content">-->
<!--					<div class="MobileSidebarMenu_list">-->
<!--						<router-link to="cart" @click="toggleDrawer">-->
<!--						<div class="MobileSidebarMenu_item"><img src="https://yastatic.net/s3/eda-front/www/assets/mobile.bag.9dae1e116046f9ae2628.svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileSidebarMenu_itemIcon">-->
<!--							<div class="MobileSidebarMenu_itemTitle">Корзина</div>-->
<!--						</div>-->
<!--						</router-link>-->
<!--						<div class="MobileSidebarMenu_item" data-testid="side-menu-promocodes-item"><img src="https://yastatic.net/s3/eda-front/www/assets/mobile.promocode.f9fb6f898e7183952f28.svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileSidebarMenu_itemIcon">-->
<!--							<div class="MobileSidebarMenu_itemTitle">Акции</div>-->
<!--						</div>-->
<!--						<div class="MobileSidebarMenu_item">-->
<!--							<svg width="1em" height="1em" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileSidebarMenu_itemIcon MobileSidebarMenu_cardIcon">-->
<!--								<path fill-rule="evenodd" clip-rule="evenodd" d="M24.273 9.75H7.727c-1.305 0-2.363 1.007-2.363 2.25v9c0 1.243 1.058 2.25 2.363 2.25h16.546c1.305 0 2.363-1.007 2.363-2.25v-9c0-1.243-1.058-2.25-2.363-2.25ZM7.727 7.5C5.117 7.5 3 9.515 3 12v9c0 2.485 2.116 4.5 4.727 4.5h16.546C26.883 25.5 29 23.485 29 21v-9c0-2.485-2.116-4.5-4.727-4.5H7.727Z" fill="#fff"></path>-->
<!--								<path fill="#fff" d="M5 12.5h22v3H5z"></path>-->
<!--							</svg>-->
<!--							<div class="MobileSidebarMenu_itemTitle">Оплата и доставка</div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="MobileSidebarMenu_list"><a href="/contacts" class="MobileSidebarMenu_item" target="_blank">Контакты</a><a href="https://yandex.ru/support/eda/index.html" rel="nofollow" class="MobileSidebarMenu_item" target="_blank">Вопросы и ответы</a><a href="https://yandex.ru/legal/termsofuse_eda/" rel="nofollow" class="MobileSidebarMenu_item" target="_blank">Пользовательское соглашение</a></div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</NavDrawer>-->
		<header-component v-on:open-nav="toggleDrawer" :cart-items="count"></header-component>
		<div class="AppDefaultLayout_root AppDefaultLayout_main">
			<div class="AppDefaultLayout_content">
				<router-view></router-view>
			</div>
		</div>
		<BottomBar v-if="homePage()" :cart-items="count" :cart-total="total"></BottomBar>
	</div>

</template>

<script>

	export default {
		data() {
			return {
			  isNavDrawerOpen: false,
			};
		},
		methods: {
			toggleDrawer() {
			  this.isNavDrawerOpen = !this.isNavDrawerOpen;
			},

			closeNavDrawer() {
			  this.isNavDrawerOpen = false;
			},
			homePage() {
				if(this.$route.path == "/" ||
            this.$route.path == "/cart" ||
            this.$route.path == "/checkout/pickup" ||
            this.$route.path == "/checkout/delivery" ||
            this.$route.path == '/thanks') {
				  return false
				} else {
				  return true
				}
			}
		},
	}
</script>