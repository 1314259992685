<template>
<div class="MobileCartPageItems_infoBlock">
	<div></div>
	<div class="MobileCartPageItems_deliverySwitcher">
		<div class="MobileDeliverySwitcherEats_receivingWrapper">
			<div class="MobileDeliverySwitcherEats_receivingTitle" v-if="titleValue">{{ titleValue }}</div>
			<div class="UISwitcherWithText_root UISwitcherWithText_mobile" :class="{ 'switched': isSwitch}">
				<div class="UISwitcherWithText_paddingBlock">
					<div class="UISwitcherWithText_leaper"></div>
					<div class="UISwitcherWithText_textItem" v-on:click="toggle" :id="item.value + group" :value="item.value" v-for="(item, index) in defaultOptions.config.items">{{ item.name }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	export default {
		name: 'Switcher',
		props: {
		    options: {
		      type: Object,
		      required: false
		    },
		    group: {
		      type: String,
		      required: false,
		      default: ''
		    },

			titleValue: {
				type: String,
				required: false,
				default: "",
			},

			defaultValue: {
				type: String,
				required: true,
			}
		},
		created () {
			this.defaultOptions = {
			  config: {
			    preSelected: 'unknown',
			    disabled: false,
			    items: [
			      { name: 'Off', value: "Off" },
			      { name: 'On', value: "On" },
			    ]
			  }
			};
		},
		data() {
			return {
			  isSwitch: false,
			  selectedValue: null,
		      selected: false,
		      selectedItem: 'unknown',
		      defaultOptions: Object,
			};
		},
		mounted () {
		    if (this.options !== null && this.options !== undefined) {
		      this.mergeDefaultOptionsWithProp(this.options)
		    }
		    if (this.defaultOptions.config.preSelected !== 'unknown') {
		      this.selectedItem = this.defaultOptions.config.preSelected
		      this.$emit('input', this.selectedItem)
		    } else if (this.modelValue) {
		      this.selectedItem = this.modelValue
		      this.$emit('input', this.selectedItem)
		    }
		},
		methods: {
			toggle(event) {
				if(this.selectedItem === event.target.id.replace(this.group, '')) return;
				this.isSwitch = this.isSwitch ? false : true;
				this.selected = true;
				this.$emit('selected', this.selected);
		        this.$emit('change', {
		          value: event.target.id.replace(this.group, ''),
		          srcEvent: event
		        });
				this.selectedItem = event.target.id.replace(this.group, '')
			},
			mergeDefaultOptionsWithProp (options) {
				var result = this.defaultOptions
				for (var option in options) {
					if (options[option] !== null && typeof (options[option]) === 'object') {
					  for (var subOption in options[option]) {
					    if (options[option][subOption] !== undefined && options[option][subOption] !== null) {
					      result[option][subOption] = options[option][subOption]
					    }
					  }
					} else {
					  result[option] = options[option]
					}
				}
			}
		}
	}
</script>

<style scoped>
 /* SWITCHER COMPONENT */
.MobileCartPageItems_infoBlock {
  background-color: #191918;
}
.MobileCartPageItems_deliverySwitcher {
  margin-top: 0;
  margin-bottom: 0;
}
.MobileDeliverySwitcherEats_receivingWrapper {
  margin: 20px 16px 20px;
}
.MobileDeliverySwitcherEats_receivingTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.UISwitcherWithText_root.UISwitcherWithText_mobile {
  border-radius: 14px;
}
.UISwitcherWithText_root {
  cursor: pointer;
  padding: 2px;
  border-radius: 100px;
  background-color: #302F2D;
}
.UISwitcherWithText_paddingBlock {
  display: flex;
  position: relative;
}
.UISwitcherWithText_root.switched .UISwitcherWithText_leaper {
  transform: translateX(100%);
}
.UISwitcherWithText_root.UISwitcherWithText_mobile .UISwitcherWithText_leaper {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.UISwitcherWithText_leaper {
  left: 0%;
  width: 50%;
  height: 100%;
  position: absolute;
  transition: transform 200ms ease;
  border-radius: 100px;
  background-color: #191918;
}
.UISwitcherWithText_root .UISwitcherWithText_textItem:nth-of-type(2) {
  color: #E0DEDA;
}
.UISwitcherWithText_textItem {
  flex: .5;
  color: #E0DEDA;
  display: flex;
  padding: 13px 0;
  z-index: 0;
  font-size: 16px;
  transition: color .25s ease;
  align-items: center;
  font-weight: normal;
  line-height: 19px;
  justify-content: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.UISwitcherWithText_textItemWrap {
  width: 80%;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.UISwitcherWithText_root .UISwitcherWithText_textItem:nth-of-type(3) {
  color: #9E9B98;
}
.UISwitcherWithText_textItemWrap {
  width: 80%;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
 /* END SWITCHER COMPONENT */
</style>