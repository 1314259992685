<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { defineProps } from 'vue'
import { useCategoriesStore } from '@/store/categories'
import { useTelegramStore } from "@/store/telegram";
import {useCartStore} from "@/store/cart";

const useCatStore = useCategoriesStore();
const tgStore = useTelegramStore();
const cartStore = useCartStore();
let tg = window.Telegram.WebApp;
var a = tg.initDataUnsafe.user;
// var a = {
//   id: 891104340,
// }
tg.expand();
tg.BackButton.hide();
tgStore.setStore(a);
cartStore.getSaleAmount(a.id);
// alert(tgStore.user.id);
useCatStore.fetchAll();
const categ = computed(() => useCatStore.list);
if(!cartStore.initLoad) {
  cartStore.clearCart();
  cartStore.initLoad = true;
}

</script>

<template>
	<h1 class="main-heading">Меню доставки</h1>
	<categories-list :categories="categ"></categories-list>
</template>

<script>
	import CategoriesList from "@/components/CategoriesList";
	import axios from 'axios'
	export default {
		components: {
			CategoriesList
		},
	}
</script>