<template>
  <div>
    <div class="drawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
      <div
        class="drawer__overlay"
        :style="{ transitionDuration: `${speed}ms` }"
      ></div>
      <div
        class="drawer__content"
        v-click-away="closeDrawer"
        :style="{
          maxHeight: maxHeight,
          transitionDuration: `${speed}ms`,
          backgroundColor: backgroundColor,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "Drawer",

  directives: {
    ClickAway: directive,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxHeight: {
      type: String,
      required: false,
      default: "400px",
    },

    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300,
    },

    backgroundColor: {
      type: String,
      required: false,
      default: "transparent",
    },
  },

  data() {
    return {
      isVisible: false,
      isTransitioning: false,
    };
  },

  watch: {
    isOpen(val) {
      this.isTransitioning = true;

      if (val) {
        this.toggleBackgroundScrolling(true);
        this.isVisible = true;
      } else {
        this.toggleBackgroundScrolling(false);
        setTimeout(() => (this.isVisible = false), this.speed);
      }

      setTimeout(() => (this.isTransitioning = false), this.speed);
    },
  },

  methods: {
    toggleBackgroundScrolling(enable) {
      const body = document.querySelector("body");

      enable ? body.classList.add('itemDrawerOpen') : body.classList.remove('itemDrawerOpen');
      body.style.overflow = enable ? "hidden" : null;
    },

    closeDrawer() {
      if (!this.isTransitioning) {
        this.$emit("close");
      }
    },
  },

  mounted() {
    this.isVisible = this.isOpen;
  },
};
</script>

<style scoped>
.drawer {
   visibility: hidden;
}
 .drawer.is-visible {
   visibility: visible;
   pointer-events: initial;
}
 .drawer.is-open .drawer__overlay {
   opacity: 0.5;
}
 .drawer.is-open .drawer__content {
   transform: translateY(0);
}
 .drawer__overlay {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   z-index: 200;
   opacity: 0;
   transition-property: opacity;
   background-color: #000;
   user-select: none;
}
 .drawer__content {
   position: fixed;
   bottom: 0;
   right: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 9999;
   overflow: auto;
   transition-property: transform;
   display: flex;
   flex-direction: column;
   transform: translateY(100%);
}
 
</style>