<template>
<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5>
    <button type="button" class="btn-close" aria-label="Close" @click="closeOffcanvas"></button>
  </div>
  <div class="offcanvas-body small">
  	{{ itemToShow }}
    <slot></slot>
  </div>
</div>
</template>

<script>
	export default {
		props: {
			itemToShow: {
				type: Object,
				required: true,
			}
		},
		data() {
			return {
				show: true,
				item: false,
			}
		},
		methods: {
			closeOffcanvas() {
				this.$emit('closeOffcanvas')
			}
		},
		name: 'bottom-offcanvas',
		mounted() {
			const myOffcanvas = document.getElementById('bottomOffcanvas')
			myOffcanvas.addEventListener('shown.bs.offcanvas', event => {
			  //
			})

		}
	}
</script>