<script setup>
import '@formkit/themes/genesis'
import {useCartStore} from '@/store/cart'
import AutocompleteInput from '../components/FormKit/AutocompleteInput'
import axios from "axios";
import {computed, reactive, ref, watch} from "vue";
import router from "@/router/router";
import {useTelegramStore} from "@/store/telegram";
const myForm = ref(null)
const cartStore = useCartStore();
const tgStore = useTelegramStore();
const userTgId = computed(() => tgStore.user.id);
const allitems = cartStore.formattedCart;

ymaps.ready(init);
let tg = window.Telegram.WebApp;
const address = reactive({ street: "", house: "", fulladdr: "", hint: "Укажите улицу и номер дома", payonline: false });

watch(address, (newValue, oldValue) => {

});

function init() {
  var myMap = new ymaps.Map('map', {
    center: [45.028802, 38.971909],
    zoom: 16,
    controls: []
  });
  let deliveryZones = {};
  var xmlhttp = new XMLHttpRequest();

  xmlhttp.onreadystatechange = function() {
    if (xmlhttp.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
      if (xmlhttp.status == 200) {
        deliveryZones = onZonesLoad(xmlhttp.responseText, myMap) ;
      }
      else if (xmlhttp.status == 400) {
        alert('There was an error 400');
      }
      else {
        alert('something else other than 200 was returned');
      }
    }
  };

  xmlhttp.open("GET", "/zones.json", true);
  xmlhttp.send();
  function onZonesLoad(json, map) {
    // Добавляем зоны на карту.
    var deliveryZones = ymaps.geoQuery(json).addToMap(myMap);

    // Задаём цвет и контент балунов полигонов.
    deliveryZones.each(function (obj) {
      obj.options.set({
        fillColor: obj.properties.get('fill'),
        fillOpacity: obj.properties.get('fill-opacity'),
        strokeColor: obj.properties.get('stroke'),
        strokeWidth: obj.properties.get('stroke-width'),
        strokeOpacity: obj.properties.get('stroke-opacity')
      });
      obj.properties.set('balloonContent', obj.properties.get('description'));
    });

    return deliveryZones;

  }
  // Создаем выпадающую панель с поисковыми подсказками и прикрепляем ее к HTML-элементу по его id.
  var suggestView1 = new ymaps.SuggestView('client_street', {boundedBy: [[45.257293, 38.750766],[45.239832, 39.173739],[45.034744, 39.290469],[44.808387, 38.827670],[45.053242, 38.693087]]});
  suggestView1.events.add("select",
      function (event) {
        var selectedValue = event.originalEvent.item.value;
        var myGeocoder = ymaps.geocode(selectedValue);
        myGeocoder.then(function(res){
            var addr = res.geoObjects.get(0);
            if(addr) {
              switch (addr.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
                case 'exact':
                  setAddr(addr);
                  break;
                case 'number':
                  setAddr(addr);
                case 'near':
                  setAddr(addr);
                case 'range':
                  setAddr(addr, false);
                  break;
                case 'street':
                  setAddr(addr, false);
                  break;
                case 'other':
                default:
                  setAddr(addr, false);
              }
              highlightResult(addr);
            } else {
              setAddr(addr, false);
            }
        });
      });

  function setAddr(objaddr = [], set = true) {
    if(set) {
      address.fulladdr = [objaddr.getThoroughfare(), objaddr.getPremiseNumber(), objaddr.getPremise()].join(' ');
      address.street = objaddr.getThoroughfare();
      address.house = [objaddr.getPremiseNumber(), objaddr.getPremise()].join(' ');
      address.hint = '';
      address.payonline = true;
    } else {
      address.fulladdr = "";
      address.street = "";
      address.house = "";
      address.hint = 'Неполный адрес, требуется уточнение номера дома или улицы';
      address.payonline = false;
    }

  }

  function highlightResult(obj) {
    var coords = obj.geometry.getCoordinates();
    var polygon = deliveryZones.searchContaining(coords).get(0);
    if (polygon) {
      //cartStore.deliveryMin = polygon.properties.get('deliverycost');
      if(cartStore.total <= parseInt(polygon.properties.get('deliverycost'))) {
        address.hint = "Минимальная сумма заказа по данному адресу "+polygon.properties.get('deliverycost')+" рублей.";
        address.payonline = false;
        tg.BackButton.show();
        tg.MainButton.text = "Вернуться в корзину";
        tg.MainButton.onClick(function() {
          tg.HapticFeedback.impactOccurred('medium');
          router.push('/cart');
        });
      } else {
        address.hint = ""
        address.payonline = true;
        tg.BackButton.show();
        tg.MainButton.text = "Оформить заказ";
        tg.MainButton.onClick(function() {
          tg.HapticFeedback.impactOccurred('medium');
          let node = myForm.value.node
          // submit the form!
          node.submit()
        });
      }
    } else {
      address.hint = "Указанный адрес не попадает в нашу зону доставки.";
      address.payonline = false;
    }
  }
}


tg.BackButton.show();
tg.MainButton.text = "Оформить заказ";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  const node = myForm.value.node
  // submit the form!
  node.submit()
});
if(checkDeliveryConditions()) {
  tg.MainButton.isVisible = true;
} else {
  tg.MainButton.isVisible = false;
}
tg.BackButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('soft');
  router.back();
});
const phoneMask = (node) => {
  // We add a check to add the cast only to range inputs
  if (node.props.id !== 'client_phone') return

  node.hook.input((value, next) => mask(value))
}

function mask(value = "-") {
	var formattedInputValue = "";
	var t = value.replace(/\D/g, '');

    if (["7", "8", "9"].indexOf(t[0]) > -1) {
        if (t[0] == "9") t = "7" + t;
        var firstSymbols = (t[0] == "8") ? "8" : "8";
        formattedInputValue = value = firstSymbols + "";
        if (t.length > 1) {
            formattedInputValue += '-' + t.substring(1, 4);
        }
        if (t.length >= 5) {
            formattedInputValue += '-' + t.substring(4, 7);
        }
        if (t.length >= 8) {
            formattedInputValue += '-' + t.substring(7, 9);
        }
        if (t.length >= 10) {
            formattedInputValue += '-' + t.substring(9, 11);
        }
    } else {
        formattedInputValue = '' + t.substring(0, 16);
    }
	return formattedInputValue;
}

let orderSend = false;

const createOrder = async (fields) => {
  if(!orderSend) {
    tg.MainButton.isVisible = false;
    orderSend = true;
    await new Promise((r) => setTimeout(r, 1000))
    var form = {
      form: fields,
      cart: allitems
    };
    axios
        .post(`https://царевыпироги.рф/api/createOrder`, {form: form.form, cart: allitems})
        .then((response) => {
          if(response.data.status === "ok") router.push('/thanks');
          console.log(response.data.datainfo);
        })
        .catch(error => {
          tg.MainButton.isVisible = true;
          orderSend = false;
          alert(error);
        });
  }


  // alert(JSON.stringify(form.cart[0].title))
}

const getCurrentDate = function(){
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  var monthText = "";
  if(month.toString().length == 1) {
    monthText = '0'+month;
  } else {
    monthText = month;
  }
  return `${year}-${monthText}-${day}`;
}

function checkDeliveryConditions() {
  if(cartStore.isDelivery) {
    if(cartStore.deliveryMin <= cartStore.total) {
      return true;
    } else {
      return false
    }
  } else {
    if(cartStore.pickupMin <= cartStore.total) {
      return true;
    } else {
      return false;
    }
  }
}


</script>

<template>
  <div class="container" v-if="checkDeliveryConditions()">
    <div id="map" style="display: none;"></div>
  <FormKit type="form" ref="myForm" @submit="createOrder" :plugins="[phoneMask]" #default="{ value }" :actions="false">
  	<div class="contacts-form">
	  	<h3>Контактные данные</h3>
	  	<div class="double">
		    <FormKit
		      type="hidden"
		      name="user_id"
		      id="user_id"
		      validation=""
          :value="userTgId"
		    />
        <FormKit
            type="hidden"
            name="delivery_type"
            id="delivery_type"
            validation=""
            :value="cartStore.isDelivery ? 'delivery' : 'pickup'"
        />
        <FormKit
            type="hidden"
            name="cart_total"
            id="cart_total"
            validation=""
            :value="cartStore.total"
        />
        <FormKit
		      type="text"
		      name="client_name"
		      id="client_name"
          value=""
		      validation="required"
		      label="Ваше имя"
		    />

		    <FormKit
		      type="text"
          inputmode="numeric"
          pattern="[0-9-]*"
		      name="client_phone"
		      id="client_phone"
          value=""
		      validation="required|length:15,15"
		      label="Телефон"
		    />
		</div>
	</div>

  	<div class="contacts-form" v-if="cartStore.isDelivery">
	  	<h3>Адрес доставки</h3>
	  	<div class="onest">
<!--        <dadataInput-->
<!--            @update:suggestion="onInput"-->
<!--        />-->
        <FormKit
            type="text"
            v-model="street"
            name="client_street"
            id="client_street"
            value=""
            placeholder="Укажите улицу"
            label="Улица"
            validation="required"
            style="width: 100%;"
        />
        <p id="errorhint" style="color: red;margin-top: -5px; font-size: 13px;">{{ address.hint }}</p>
        <p id="messagehint" style="color: blue;margin-top: -5px; font-size: 13px;"></p>
        <div class="double">

        <FormKit
            type="hidden"
            v-model="address.house"
            name="client_house"
            value=""
            placeholder="Укажите дом"
            id="client_house"
            label="Номер дома"
            validation="required"
        />
        <FormKit
            type="hidden"
            v-model="address.fulladdr"
            name="client_fulladdr"
            id="client_fulladdr"
            value=""
        />
        </div>
		<div class="double thrible">
		    <FormKit
		      type="text"
		      name="client_flat"
		      id="client_flat"
          value=""
		      validation=""
          placeholder="Укажите кв./офис"
		      label="Квартира"
		    />
		    <FormKit
		      type="number"
          inputmode="numeric"
          pattern="[0-9]*"
		      name="client_podezd"
		      id="client_podezd"
          value=""
		      validation=""
		      label="Подъезд"
		    />
		    <FormKit
		      type="number"
          inputmode="numeric"
          pattern="[0-9]*"
		      name="client_floor"
		      id="client_floor"
          value=""
		      validation=""
		      label="Этаж"
		    />
		</div>
      </div>
	</div>
    <div class="contacts-form">
      <h3>Дата и время</h3>
      <div>
        <FormKit
            type="hidden"
            name="delivery_date"
            id="delivery_date"
            validation="required"
            v-model="formatdate"
            label="Дата"
        />
        <VueDatePicker
            @update:model-value="selectDate"
            placeholder="Укажите дату доставки"
            locale="ru"
            required
            v-model="date"
            dark
            :enable-time-picker="false"
            auto-apply
            disable-year-select
            :min-date="new Date()"
            :format="format"
        />
        <br>
        <FormKit
            type="select"
            label="Время доставки"
            name="delivery_time"
            validation="required|starts_with:с"
            :validation-messages="{
              starts_with: ({ node: { value } }) =>
                `Укажите доступное время для доставки или выберите другую дату`,
            }"
            v-if="date && times"
            :options="times"
        />
        <FormKit
            type="radio"
            name="client_paytype"
            label="Оплата"
            validation="required"
            value="terminal"
            :options="[
              // { label: 'Онлайн', value: 'online', attrs: { disabled: !address.payonline } },
              { label: 'Наличными', value: 'cash' },
              { label: 'Картой', value: 'terminal' },
            ]"
            help="Укажите способ оплаты"
        />
        <FormKit
            type="textarea"
            name="client_comment"
            value=""
            label="Комментарий к заказу"
            rows="4"
        />
      </div>
    </div>


    <!--<pre wrap>{{ allitems }}</pre>-->
  </FormKit>

  <p><em><small>Нажимая кнопку "Оформить заказ" Вы соглашаетесь с политикой конфиденциальности и пользовательским соглашением.</small></em></p>
</div>
  <div v-else style="justify-content: center; display: flex;padding-top: 40px; font-weight: bold;
font-size: 16px;">
    <p v-if="cartStore.isDelivery">Минимальная сумма на доставку от {{ cartStore.deliveryMin }} руб.</p>
    <p v-if="!cartStore.isDelivery">Минимальная сумма навынос от {{ cartStore.pickupMin }} руб.</p>
  </div>
</template>



<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import dadataInput from "@/components/FormKit/dadataInput.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";

export default {
  components: { VueDatePicker, dadataInput },
  data() {
    return {
      house: null,
      street: null,
      fulladdr: null,
      suggestion: null,
      value: null,
      date: null,
      formatdate: null,
      times: [],
      currentTime: "",
      deliveryType: ""
    };
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  mounted() {
    this.deliveryType = this.$route.params.type;
    this.getNow();
    this.date = new Date();
    this.timesLoad();
    this.selectDate(this.date);
  },
  methods: {
    onInput(sug) {
      this.suggestion = sug;
      if(this.suggestion) {
        this.fulladdr = this.suggestion.value;
        if(this.suggestion.data.house) this.house = this.suggestion.data.house
        if(this.suggestion.data.street_with_type) this.street = this.suggestion.data.street_with_type
        if(this.suggestion.data.block_type) this.house += " "+this.suggestion.data.block_type+" "+this.suggestion.data.block
      }

    },
    selectDate(date) {
      if(date) {
        this.timesReload();
        this.formatdate = this.format(date);
        this.timesLoad(this.formatdate);
      }
    },
    getNow: function() {
      const today = new Date();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.currentTime = time;
    },
    timesReload() {
      this.times = null;
    },
    timesLoad(dateFormat) {
      axios
          .post(`https://царевыпироги.рф/api/times`, {now: this.formatdate, time: this.currentTime, format: this.deliveryType})
          .then((response) => {
            this.times = response.data;
          })
          .catch(error => console.log(error));
    },
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      var monthText = "";
      if(month.toString().length === 1) {
        monthText = '0'+month;
      } else {
        monthText = month;
      }
      return `${day}.${monthText}.${year}`;
    }
  }
}
</script>

<style>
	.double {
	  display: flex;
	  justify-content: space-between;
	}
	.double > .formkit-outer {
	  width: calc(50% - 0.5em);
	}
	.thrible > .formkit-outer {
	  width: calc(33% - 0.5em);
	}
	.formkit-input {
		color: white !important;
	}
  .vue-dadata__suggestions {
    background-color:#191918;
    border: 1px solid #b3b3b3;
  }
  .vue-dadata__input {
    font-size: 14px;
    width: 100%;
    height: 47px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    transition: .3s;
    box-sizing: border-box;
    color: white;
    padding: 0 5px;
    background: #191918;
    margin-bottom: 20px;
  }
	.formkit-help {
		color: white;
	}
  select.formkit-input {
    padding-right: calc(var(--fk-padding-base) * 7);
    background: #212121;
  }
  ymaps * {
    color: black;
  }
</style>