import { defineStore } from 'pinia'
import { useCartStore } from '@/store/cart'
const fakeStoreUrl = 'https://царевыпироги.рф/api'


export const useProductStore = defineStore({
  id: 'products',

  
  state: () => ({
    items: {},
    loadedItem: {},
    ids: [],
    category: null,
    categoryTitle: null,
  }),

  getters: {
    list(state) {
      const cartStore = useCartStore();
      return this.ids.map(i => this.items[i])
    },
    getItem(state) {
      return this.loadedItem;
    },
    loaded(state) {
      return this.ids.length > 0
    },
    loadedCat(state) {
      return this.category !== null
    }
  },

  actions: {
    async fetchAll(slug) {
      if(slug == this.category) return;
      if(slug == 'item') return;
      this.category = slug;
      const res = await fetch(`${fakeStoreUrl}/foodBySlug/${slug}`)
      const data = await res.json()
      this.ids = data.map((product) => {
        this.items[product.id] = product
        return product.id
      })
    },
    async fetchItem(itemId) {
      const res = await fetch(`${fakeStoreUrl}/getItem/${itemId}`)
      const data = await res.json()
        this.loadedItem = data
        return data.id
    }


  },
});
